class Hangman {
    constructor (word, year, remainingGuesses) {
        this.word = word.toLowerCase().split('')
        this.year = year
        this.remainingGuesses = remainingGuesses
        this.guessedLetters = []
        this.status = 'playing'
    }
    get puzzle() {
        let currentState = ''
        let freeLetters = [' ', '.', '(', ')', ';', '&', ',', ':']
        this.word.forEach((char) => {
            if(this.guessedLetters.includes(char) || freeLetters.includes(char)) {
                currentState += char
            } else {
                currentState += '*'
            }
        })
        return currentState 
    }
    calculateStatus() {
        let freeLetters = [' ', '.', '(', ')', ';', '&', ',', ':']
        const finished = this.word.every((letter) => this.guessedLetters.includes(letter) || freeLetters.includes(letter)) 

        if (this.remainingGuesses === 0) {
            this.status = 'failed'
        } else if (finished) {
            this.status = 'finished'
        } else {
            this.status = 'playing'
        }
    }
    get statusMessage() {
        if (this.status === 'playing') {
            return `Published in: ${this.year} --- Guesses left: ${this.remainingGuesses}`
        } else if (this.status === 'failed') {
            return `Nice try! The game was "${this.word.join('')}"`
        } else {
            return `Great work! You guessed the game!`        }
    }
    makeGuess(guess) {
        guess = guess.toLowerCase()
        const isUnique = !this.guessedLetters.includes(guess)
        const isBadGuess = !this.word.includes(guess)
    
        if (this.status !== 'playing') {
            return
        }
    
        if (isUnique) {
            this.guessedLetters.push(guess)
        }
        if (isUnique && isBadGuess) {
            this.remainingGuesses--
        }
        this.calculateStatus()
    }
}

export { Hangman as default }