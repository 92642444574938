const getPuzzle = async (wordCount) => {
    const response = await fetch(`https://bgg-json.azurewebsites.net/hot`)
    if (response.status === 200) {
        const data = await response.json()
        let gameNum = Math.floor(Math.random() * 50)
        if (data[gameNum].name === 'Orléans') {
            return data[gameNum - 1]
        } else {
            return data[gameNum]
        }
    } else {
        throw new Error('Unable to fetch puzzle')
    }
}

export { getPuzzle as default }